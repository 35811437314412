import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Helpers from '../../../Config/Helpers';

function Sidebar({ isOpen, toggleSidebar }) {
    const navigate = useNavigate();
    const handleLogout = () => {
        Helpers.removeItem('user');
        Helpers.removeItem('token');
        Helpers.removeItem('selectedPackage')
        Helpers.removeItem('user_id')
        navigate('/login');
    };
    return (
        <>
        {/* Sidebar for Desktop */}
        <div className='hidden lg:block basis-[18%] bg-pinkbackground h-[89vh] overflow-auto'>
          <SidebarContent handleLogout={handleLogout} />
        </div>
  
        {/* Mobile Sidebar Sliding Menu */}
        <div className={`lg:hidden fixed inset-0 z-50 bg-black bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}>
          <div className={`fixed left-0 top-0 w-[75%] h-full bg-pinkbackground transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
            <div className="flex justify-end p-4">
              <button onClick={toggleSidebar} >
                <i className="fa fa-times" style={{ fontSize: "1.5rem" , color: "white" }}></i>
              </button>
            </div>
            <SidebarContent handleLogout={handleLogout} />
          </div>
        </div>
      </>
    )
}
function SidebarContent({ handleLogout }) {
    return (
      <div className='flex flex-col justify-center'>
        <nav>
          <ul>
            <li className="p-5 text-lg font-bold d-flex">
              <NavLink
                to="/user/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? 'text-[#FF7A50] bg-[#e2e2e215] w-full rounded-lg p-5'
                    : 'text-text hover:text-primary p-5'
                }
              >
                <i className="fa-regular fa-house mr-3"></i>
                Home
              </NavLink>
            </li>
  
            <li className='p-5 text-lg font-bold d-flex'>
              <NavLink
                to='/user/profile'
                className={({ isActive }) =>
                  isActive
                    ? 'text-[#FF7A50] bg-[#e2e2e215] w-full rounded-lg p-5'
                    : 'text-text hover:text-primary p-5'
                }
              >
                <i className="fa-regular fa-user mr-3"></i> Profile
              </NavLink>
            </li>
  
            <li className='p-5 text-lg font-bold d-flex'>
              <NavLink
                to='/user/setting'
                className={({ isActive }) =>
                  isActive
                    ? 'text-[#FF7A50] bg-[#e2e2e215] w-full rounded-lg p-5'

                    : 'text-text hover:text-primary p-5'
                }
              >
                <i className="fa-regular fa-gear mr-3"></i> Settings
              </NavLink>
            </li>
  
            <li className='p-5 text-lg font-bold d-flex'>
              <button
                className='text-text hover:text-primary p-5'
                onClick={handleLogout}
              >
                <i className="fa-solid fa-right-from-bracket mr-3"></i> Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
export default Sidebar
