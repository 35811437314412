import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { NavLink, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../layouts/ThemeContext';
import Helpers from '../../../Config/Helpers';

function PlanCard() {
    const { isDarkMode } = useContext(ThemeContext);
    const [plans, setPlans] = useState([])
    const [userData, setUserData] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'))
    const getData = async () => {
        const userId = localStorage.getItem('user_id');
        const response = axios.get(`${Helpers.apiUrl}getUserPackage/${userId}`)
            .then(response => {
                setUserData(response.data.user);
                setPackageData(response.data.package);
                setLoading(false);
            })
            .catch(error => {
                setError(error.response ? error.response.data.error : 'An error occurred');
                setLoading(false);
            });
    }
    useEffect(() => {
        getData()
    }, [])

    const fetchPlans = async () => {
        const response = await axios.get(`${Helpers.apiUrl}plans/show`)
        setPlans(response.data.data)
        // console.log('plans', response)
    }
    const navigate = useNavigate()
    const selectPackage = (plan) => {
        if (localStorage.getItem('token')) {
            localStorage.setItem('selectedPackage', JSON.stringify(plan));
            navigate('/user/checkout');
        } else {
            Helpers.toast('error', "Please Login First to continue!")
            navigate('/login')
        }
    }
    useEffect(() => {
        document.title = "User Settings | Clockin";
        fetchPlans()
    }, [])
    return (
        <>
            <div className="lg:basis-[82%] basis-[100%] h-[89vh] overflow-auto bg-[#F9F9F9]">
                <h1 className='text-2xl m-5 font-bold '>Current Plan :-</h1>
                {/* <h1 className='m-5'>Your Package</h1> */}
                <div className="container mx-auto p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">

                        <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                                <i className="fa-regular fa-user text-white" style={{ fontSize: "1.5rem" }}></i>
                            </div>
                            <div className="ml-4">
                                <h3 className="text-xl font-semibold">User Name</h3>
                                <p className="text-gray-600">{userData?.name || 'N/A'}</p>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                                <i className="fa-solid fa-dollar-sign" style={{ fontSize: "1.5rem" }}></i>
                            </div>
                            <div className="ml-4">
                                <h3 className="text-xl font-semibold">Package Name</h3>
                                <p className="text-gray-600">{packageData?.plan_name || 'N/A'}</p>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                                <i className="fa-solid fa-calendar" style={{ fontSize: "1.5rem" }}></i>
                            </div>
                            <div className="ml-4">
                                <h3 className="text-xl font-semibold">Expiration Date</h3>
                                <p className="text-gray-600">{userData?.trial_ends_at
                                    ? new Date(userData.trial_ends_at).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric',
                                    })
                                    : 'N/A'
                                }
                                </p>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                                <i className="fa-regular fa-building" style={{ fontSize: "1.5rem" }}></i>
                            </div>
                            <div className="ml-4">
                                <h3 className="text-xl font-semibold">Role</h3>
                                <p className="text-gray-600">{userData?.role || 'N/A'}
                                </p>
                            </div>
                        </div>

                        {/* Other data you need to display */}

                    </div>
                </div>
                <h1 className='text-2xl m-5 font-bold'>Upgrade Plan :-</h1>
                <div className="container mx-auto px-4">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar]}
                        spaceBetween={10}
                        slidesPerView={1}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                        navigation
                        pagination={{
                            clickable: true,
                            renderBullet: (index, className) => {
                                return `<span class="${className} mx-1 rounded-full cursor-pointer transition duration-150 ease-in-out h-3 w-3 mt-10 ${index === 0 ? 'bg-[#FF7A50]' : 'bg-gray-500'}"></span>`;
                            },
                        }}
                        scrollbar={{ draggable: true }}
                        className="mySwiper"
                        style={{ position: 'relative' }}
                    >

                        {plans.map(plan => (


                            <SwiperSlide key={plan.id} className="flex myslide">
                                <div className="relative bg-plancard rounded-tr-3xl rounded-bl-3xl rounded-tl-none rounded-br-none p-6 max-w-sm lg:w-full sm:w-full mx-8 my-5 text-white shadow-sm flex flex-col items-center ">
                                    <h3 className={`${isDarkMode ? 'text-[#ffffff]' : 'text-[#FF8B42]'} font-semibold m-5`}>{plan.plan_name}</h3>
                                    <p className="text-3xl font-bold mb-8 text-[#FF8B42]">${plan.price}</p>
                                    <hr className="border-[#81818146] w-full border-t-1 mb-6" />

                                    <div className={`flex flex-col justify-between items-center w-full border p-5 border-orange-400 rounded-lg ${isDarkMode ? '' : 'bg-white'}`}>
                                        <div className="flex justify-between items-center w-full mb-2">
                                            <span className="text-[#FF8B42] font-semibold">Employee</span>
                                            <span className={`${isDarkMode ? 'text-[#ffffff]' : 'text-[#FF8B42]'}`}>{plan.employees_limit !== null ? plan.employees_limit : 'Unlimited'}</span>
                                        </div>
                                        <div className="flex justify-between items-center w-full">
                                            <span className="text-[#FF8B42] font-semibold">Tasks</span>
                                            <span className={`${isDarkMode ? 'text-[#ffffff]' : 'text-[#FF8B42]'}`}>{plan.tasks_limit !== null ? plan.tasks_limit : 'Unlimited'}</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full mb-6 space-y-3 p-5">
                                        <div className="flex justify-between">
                                            <span className='text-text'>Timesheet</span>
                                            <span className="text-green-500">✔</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='text-text'>Export to Excel</span>
                                            <span className="text-green-500">✔</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='text-text'>Note</span>
                                            <span className="text-green-500">✔</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='text-text'>Report</span>
                                            {plan.type !== 'Offline' ? <span className="text-green-500">✔</span> : <span className="text-red-500">✖</span>}
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='text-text'>Data Backup</span>
                                            {plan.type !== 'Offline' ? <span className="text-green-500">✔</span> : <span className="text-red-500">✖</span>}
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='text-text'>Syncing with server</span>
                                            {plan.type !== 'Offline' ? <span className="text-green-500">✔</span> : <span className="text-red-500">✖</span>}
                                        </div>
                                        <div className="flex justify-between">
                                            <span className='text-text'>Report</span>
                                            {plan.type !== 'Offline' ? <span className="text-green-500">✔</span> : <span className="text-red-500">✖</span>}
                                        </div>
                                        {plan.offline_mode ? <div className='flex justify-center text-[#FF8B42] font-bold pt-5'>Offline</div> : ''}
                                    </div>

                                    <NavLink className="bg-orange-500 text-white rounded-full px-6 py-2 font-semibold hover:bg-orange-600 transition-colors mb-6" onClick={() => selectPackage(plan)}>
                                        Choose Plan
                                    </NavLink>

                                    <p className="text-gray-100 text-xs text-center">{plan.description}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default PlanCard
