import React, { useEffect, useState } from 'react'
import Helpers from '../../../Config/Helpers';
import axios from 'axios';

function PublishHeaderDropdown({ data }) {
    const [isPublish, setIsPublish] = useState(data.is_header);
  
    useEffect(() => {
        setIsPublish(data.is_header);
    }, [data.is_header]);
  
    const handleStatusChange = async (e) => {
        const newStatus = e.target.checked ? 1 : 0; 
  
        try {
            const response = await axios.post(`${Helpers.apiUrl}update-header-status/${data.slug}`, {
                is_header: newStatus,
            });
  
            if (response.status === 200) {
                setIsPublish(newStatus);
                Helpers.toast("success", "Status updated successfully");
            } else {
                console.error('Failed to update status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            Helpers.toast("error", "Failed to update status");
        }
    };
  
    return (
        <div>
            <input
              type="checkbox"
              checked={parseInt(isPublish) === 1} 
              onChange={handleStatusChange} 
              className="outline-none p-2 rounded bg-[#f9f9f9]"
            />
            <label className="ml-2">
              {parseInt(isPublish) === 1 ? 'Publish' : 'Inactive'} 
            </label>
        </div>
    );
  }
  



export default PublishHeaderDropdown
