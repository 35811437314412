import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Helpers from '../../../Config/Helpers'
import { usePage } from '../../../layouts/PageContext';

function ButtonLinks({selectedElement}) {
    const [data, setData] = useState([])
    const { formData, setFormData } = usePage();
    const getData = async () => {
        const response = await axios.get(`${Helpers.apiUrl}page/show`)
        setData(response.data.data)
    }
    useEffect(() => {
        getData()
    }, [])
    const onLinkChange = (event, linkType) => {
        const { value } = event.target;
      
        if (
          selectedElement.rowIndex !== null &&
          selectedElement.columnIndex !== null &&
          selectedElement.itemIndex !== null
        ) {
          const rows = [...formData.rows];
          const column = rows[selectedElement.rowIndex].columns[selectedElement.columnIndex];
      
          let item;
      
          if (column.data) {
            const columnData = JSON.parse(column.data);
            item = columnData.items[selectedElement.itemIndex] || {};
            item.style = item.style || {};

            if (linkType === 'pageLink') {
              item.style.buttonLink = value; 
            } else if (linkType === 'customLink') {
              item.style.buttonLink = value;
            }
      
            column.data = JSON.stringify({ ...columnData, items: columnData.items });
          } else {
            item = column.items[selectedElement.itemIndex] || {};
            item.style = item.style || {};
            if (linkType === 'pageLink') {
              item.style.buttonLink = value; 
            } else if (linkType === 'customLink') {
              item.style.buttonLink = value; 
            }
          }
      
          setFormData({ ...formData, rows });
        } else {
          console.error('Selected element indices are not set correctly.');
        }
      };
      
    return (
        <div>
        <span className='text-md font-bold'>
          <i className="fa-sharp fa-solid fa-caret-down mr-3 mt-3"></i>Add Links to Button
        </span>
        <div className='block justify-between w-full mt-5'>
          <div className='w-full'>
            <label className='font-semibold'>Page's Link: </label>
            <select
              className='px-2 py-2 my-2 rounded shadow-sm w-[100%] outline-none'
              onChange={(e) => onLinkChange(e, 'pageLink')}  // Handle page link change
            >
              <option>Select a Page</option>
              {data.filter((page)=> page.is_publish === '1').map((pages, index) => (
                <option key={index} value={pages.slug}>{pages.name}</option>
              ))}
            </select>
          </div>
          <div className='w-full'>
            <label className='font-semibold'>Custom Link: </label>
            <input
              type='text'
              placeholder='https://linkedin.com/'
              className='px-2 py-2 mt-2 rounded shadow-sm w-[100%] outline-none'
              onChange={(e) => onLinkChange(e, 'customLink')}  // Handle custom link change
            />
          </div>
        </div>
      </div>
      
    )
}

export default ButtonLinks
