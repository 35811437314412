import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HelmetWrapper from '../../../Config/HelmetWrapper';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Main from './Main';

function Dashboard() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    document.title = 'User Dashboard | Clockin'
  })
  return (
    <div>
      <HelmetWrapper />
      <Header toggleSidebar={toggleSidebar} />
      <div className='flex flex-row'>

        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <Main />
      </div>
    </div>
  )
}

export default Dashboard
