import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Helpers from '../../../Config/Helpers';

function MainSetting() {
  useEffect(() => {
    document.title = "User Profile | Clockin";
  }, []);

  const userId = localStorage.getItem("user_id");
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState('')
  const [contact, setPhoneNumber] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await axios.post(`${Helpers.apiUrl}user/${userId}/profile/update`, { name, contact });
      if (response.status === 200) {
        Helpers.toast("success", 'Profile updated successfully!');
        fetchProfile()
        setLoading(false)
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      Helpers.toast("error",'Failed to update profile');
      setLoading(false)
    }
  };

  const handleUpdatePassword = async (e) => {
    setLoading1(true)
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("New passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(`${Helpers.apiUrl}user/${userId}/password/update`, {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmPassword,
      });
      setMessage('Password updated successfully');
      Helpers.toast("success", 'Password updated successfully')
      setLoading1(false)
    } catch (error) {
      console.error('Error updating password:', error);
      Helpers.toast("error",'Failed to update password');
      setLoading1(false)
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}user/${userId}/profile`);
      // console.log('profilew', response)
      setProfile(response.data.data);
      setName(response.data.data.name);
      setImage(response.data.data.avatarPath)
      // console.log('imagere', response.data.data.avatarPath)
      setPhoneNumber(response.data.data.contact);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [userId]);

  return (
    <div className='basis-[100%] lg:basis-[82%] h-[89vh] overflow-auto bg-[#F9F9F9]'>
      <h1 className='text-2xl m-5 font-bold'>Customize Your Profile</h1>
      <div
        className="container "
        style={{
          borderRadius: "20px",
          background: "#F9F9F9",
          marginTop: "2%",
        }}
      >
        <div className="row  mt-5">
          <div className="col-md-12">
            {/* <h1 className="text-3xl font-bold mb-5">Profile</h1> */}
            <div className="bg-white rounded-lg  p-5 flex">
              {/* <div> */}
              {profile ? (
                <div className="flex">
                  <div className="flex-shrink-0">
                 
                      <img
                        className="rounded-full"
                        src="/assets/profile.png"
                        alt="Default Avatar"
                        style={{ width: "120px", height: "120px" }}
                      />
                  </div>
                  <div className="ml-5 flex-grow">
                    <h1 className="text-2xl font-bold mb-3">{profile.name}</h1>
                    <div className=" block lg:flex justify-between " style={{ paddingRight: "10px" }}>
                      <div className="flex-grow">
                        <div className="flex items-center text-gray-900 mb-2">
                          <i className="fas fa-phone text-indigo-500 mr-2"></i>
                          <span className="font-bold mr-1">Phone Number:</span>
                          {profile.contact}
                        </div>
                      </div>
                      <button
                        onClick={toggleEditMode}
                        className="btn py-2 px-4 ml-10"
                        style={{
                          background: "#ff7a50",
                          color: "white",
                        }}
                      >
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>Loading profile data...</div>

              )}
              {/* </div> */}
            </div>
          </div>
        </div>
        {editMode && (
          <div
            className="mt-5 bg-white rounded-lg p-5"
            style={{ background: "white" }}
          >
            <div className="space-y-4">
              
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex items-center space-x-4" style={{ margin: "2% 0" }}>
                  <label className="text-gray-700 text-l font-bold" style={{ width: "30%" }}>
                    Name:
                  </label>
                  <input
                    type="text"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Name"
                    value={name}
                    onChange={handleNameChange}
                    style={{ width: "70%", background: "#fbfbfb" }}
                  />
                </div>
                <div className="flex items-center space-x-4" style={{ margin: "2% 0" }}>
                  <label className="text-gray-900 text-l font-bold" style={{ width: "30%" }}>
                    Phone Number:
                  </label>
                  <input
                    type="text"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Phone Number"
                    value={contact}
                    onChange={handlePhoneNumberChange}
                    style={{ width: "70%", background: "#fbfbfb" }}
                  />
                </div>
                <div className="flex items-center space-x-4" style={{ margin: "3% 0" }}>
                  <label
                    className="text-gray-900 text-sm font-bold"
                    style={{ width: "30%" }}
                  ></label>
                  <button
                    type="submit"
                    className="mt-3 py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700"
                    style={{ background: "#ff7a50", color: "white" }}
                  >
                    {loading ? 'Please Wait...' : 'Update Profile'}
                  </button>
                </div>
              </form>
              <form onSubmit={handleUpdatePassword} className="space-y-4">
                <div className="flex items-center space-x-4">
                  <label className="text-gray-900 text-l font-bold" style={{ width: "30%" }}>
                    Current Password:
                  </label>
                  <input
                    type="password"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Current Password" required
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    style={{ width: "70%", background: "#fbfbfb" }}
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <label className="text-gray-900 text-l font-bold" style={{ width: "30%" }}>
                    New Password:
                  </label>
                  <input
                    type="password"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="New Password" required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ width: "70%", background: "#fbfbfb" }}
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <label className="text-gray-900 text-l font-bold" style={{ width: "30%" }}>
                    Confirm New Password:
                  </label>
                  <input
                    type="password"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Confirm New Password" required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ width: "70%", background: "#fbfbfb" }}
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <label
                    className="text-gray-900 text-sm font-bold"
                    style={{ width: "30%" }}
                  ></label>
                  <button
                    type="submit"
                    className="mt-3 py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700"
                    style={{ background: "#ff7a50", color: "white" }}
                  >
                    {loading1 ? 'Please Wait...' : 'Update Password'}
                  </button>
                </div>
                {/* {message && <p>{message}</p>}   */}
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MainSetting
