import React, { useState, useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useImages } from './ImageContext';

import { ThemeContext } from './ThemeContext';
import axios from 'axios';
import Helpers from '../Config/Helpers';
import { DataContext } from '../Context/HeaderContext';

const Header = ({ logourl }) => {
  const { isDarkMode, setisDarkMode } = useContext(ThemeContext);
  const [currentImages, setCurrentImages] = useState({ 'hero-1': '', 'hero-2': '' });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [data, setData] = useState([])
  const data = useContext(DataContext);
  // const getData = async () => {
  //   const response = await axios.get(`${Helpers.apiUrl}page/show`)
  //   setData(response.data.data)
  // }
  const fetchImage = async () => {
    const sections = [
      { section: "hero", id: "1" },
    ];
    const mode = isDarkMode ? "dark" : "light";
    try {
      const response = await axios.post(`${Helpers.apiUrl}get-image`, {
        sections: sections.map(s => `${s.section}-${s.id}`),
        mode
      });
      const newImages = {};
      response.data.images.forEach(image => {
        newImages[image.section] = image.image_url;
      });
      setCurrentImages(newImages);
    } catch (error) {
      console.error("Error in fetching images", error);
    }
  };

  // useEffect(() =>{
  //   if (!data) {
  //     getData();
  //     fetchImage();
  //   }
  // },[data])

  useEffect(() => {
    // getData()
    fetchImage();
  }, [isDarkMode]);


  return (
    <div className="container mx-auto px-4 pt-8 lg:pt-16">
      <div className="flex justify-between items-center">
        <div className='flex'>
          <div className="lg:hidden flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-text focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="#FF7A50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}></path>
              </svg>
            </button>
          </div>
          {['1'].map(id => (
            <a href="/" key={id}>
              <img src={
                currentImages[`hero-${id}`]
                  ? `${Helpers.basePath}${currentImages[`hero-${id}`]}`
                  : isDarkMode
                    ? "assets/logo.png"
                    : "assets/blacklogo.png"
              } alt="Logo" className="h-5 lg:h-12 lg:mb-0 ml-4 lg:ml-0" />
            </a>
          ))}
        </div>
        <nav className="hidden lg:flex">
          <ul className="flex space-x-4 lg:space-x-16 pr-8">
            {data
              .filter((page) => page.is_header === '1') // Or .filter((page) => page.is_custom === 0) if is_custom is a number
              .map((page, index) => (
                <li key={page.id}>
                  <NavLink
                    to={`/${page.slug}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-orange-500 hover:text-orange-500'
                        : 'text-text hover:text-primary'
                    }
                    end
                  >
                    {page.name}
                  </NavLink>
                </li> // Closing <li> tag was missing
              ))}
          </ul>

        </nav>

        <div className="lg:block mr-[35%] lg:mr-[6%] md:mr-[20%]">
          <div className="relative p-4">
            <button
              className={`absolute top-0 left-0 w-[5.5rem]  rounded-lg text-[#f2f5f5] py-3 font-[0.5rem] text-xs lg:text-base
                          ${isDarkMode ? 'bg-secondary ' : 'bg-[#FF7A50] font-bold z-10 lg:w-[8rem] w-[6rem] ml-[-21%]'}`}
              onClick={() => setisDarkMode(false)}
            >
              Light {!isDarkMode ? 'Mode' : ''}
            </button>
            <button
              className={`absolute top-0 left-6  rounded-xl text-text ml-12 py-3 text-xs lg:text-base
                          ${isDarkMode ? 'bg-background font-bold z-20 lg:w-[8rem] w-[6rem] mr[5%]' : 'bg-[#EAEAEA] z-20 w-[5.5rem] lg:ml-[5.5rem] ml-[3.5rem]'}`}
              onClick={() => setisDarkMode(true)}
            >
              Dark {isDarkMode ? 'Mode' : ''}
            </button>
          </div>
        </div>
        <div className='hidden lg:block lg:ml-[5%] ml-0'>
          <Link to='/login'>
            <img src='/assets/user.png' className='cursor-pointer pt-4' alt='' /></Link>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden" onClick={() => setIsMenuOpen(false)}>
          <div className="fixed left-0 top-0 w-80 h-full bg-background text-text shadow-lg z-50" onClick={(e) => e.stopPropagation()}>
            <button onClick={() => setIsMenuOpen(false)} className="absolute top-14 right-5 text-text focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <nav className="mt-16">
              {['1'].map(id => (
                <a href="/">
                  <img src={
                    currentImages[`hero-${id}`]
                      ? `${Helpers.basePath}${currentImages[`hero-${id}`]}`
                      : isDarkMode
                        ? "assets/logo.png"
                        : "assets/blacklogo.png"
                  } alt="Logo" className="h-5 lg:h-12 lg:mb-0 ml-4 lg:ml-0 pl-5" />
                </a>
              ))}
              {/* <a href="/"><img src={isDarkMode ? 'assets/logo.png' : 'assets/blacklogo.png'} alt="Logo" className="h-5 lg:h-12 lg:mb-0 ml-4 lg:ml-0 pl-5" /></a> */}
              <ul className="flex flex-col space-y-5 px-10 pt-8">
                {data
                  .filter((page) => page.is_header === '1') // Or .filter((page) => page.is_custom === 0) if is_custom is a number
                  .map((page, index) => (
                    <li key={page.id}>
                      <NavLink
                        to={`/${page.slug}`}
                        className={({ isActive }) => isActive ? 'text-orange-500 hover:text-orange-500' : 'text-text hover:text-primary'}
                        end
                        onClick={() => setIsMenuOpen(false)}
                      >
                        {page.name}
                      </NavLink>
                    </li>
                    // <li>
                    //   <NavLink
                    //     to="/download"
                    //     className={({ isActive }) => isActive ? 'text-orange-500 hover:text-orange-500' : 'text-text hover:text-primary'}
                    //     onClick={() => setIsMenuOpen(false)}
                    //   >
                    //     Download
                    //   </NavLink>
                    // </li>
                    // <li>
                    //   <NavLink
                    //     to="/FAQs"
                    //     className={({ isActive }) => isActive ? 'text-orange-500 hover:text-orange-500' : 'text-text hover:text-primary'}
                    //     onClick={() => setIsMenuOpen(false)}
                    //   >
                    //     FAQs
                    //   </NavLink>
                    // </li>
                    // <li>
                    //   <NavLink
                    //     to="/support"
                    //     className={({ isActive }) => isActive ? 'text-orange-500 hover:text-orange-500' : 'text-text hover:text-primary'}
                    //     onClick={() => setIsMenuOpen(false)}
                    //   >
                    //     Support
                    //   </NavLink>
                    // </li>
                    // <li>

                    //   <NavLink
                    //     to="/news"
                    //     className={({ isActive }) => isActive ? 'text-orange-500 hover:text-orange-500' : 'text-text hover:text-primary'}
                    //   >
                    //     News & Article
                    //   </NavLink>
                    // </li>
                  ))}
                <li className='flex items-center'>
                  <img src='/assets/user.png' className='cursor-pointer mr-4' alt='' />
                  <Link to='/login'>
                    Login </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
