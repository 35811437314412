import React, { useContext, useEffect, useState } from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import { ThemeContext } from '../layouts/ThemeContext';
import AnimatedText from '../layouts/AnimatedText';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../Config/Helpers';

function Login() {
    const { isDarkMode } = useContext(ThemeContext);
    useEffect(() => {
        document.title = 'Login | Clockin'
    })
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleSignup1 = () => {
        navigate('/register')
    }
    const handleSignup = async (event) => {
        event.preventDefault();
        try {
            setLoading(true)
            const response = await axios.post(`${Helpers.apiUrl}loginuser`, formData);
            // console.log(response, 'response');
            Helpers.setItem('user', response.data.user, true);
            Helpers.setItem('token', response.data.token);
            Helpers.setItem('user_id', response.data.user.id);
            setLoading(false)
            Helpers.toast('success', 'Login Successfully')
            if (Helpers.getItem('selectedPackage')) {
                navigate('/user/checkout')
            }
            else {
                navigate('/user/dashboard')
            }
        } catch (error) {
            // console.log(error.response.data.error)
            Helpers.toast('error', error.response.data.error)
            setLoading(false)
        }
    }
    return (
        <div>
            <div>
                <div
                    className='bg-cover bg-center bg-no-repeat h-[37vh] lg:h-[50vh] w-full'
                    style={{ backgroundImage: `url(${isDarkMode ? '/assets/bg1.png' : '/assets/bg2.png'})` }}
                >
                    <Header />
                    <div className='py-5 mt-10'>
                        <h1 className='text-text font-semibold text-3xl pt-8 text-center'>Login</h1>

                    </div>
                </div>

                <AnimatedText>
                    <form onSubmit={handleSignup}>
                        <div className="bg-pinkbackground m-4 rounded-3xl w-[90%] mx-auto">
                            <div className="flex flex-col lg:flex-row justify-between items-center px-8 md:px-16 lg:px-[160px] py-8 container mx-auto pt-5  w-full  ">
                                <div className="text-center lg:text-left w-[100%] lg:w-[50%] lg:order-1 order-2">
                                    <div className='block text-left' >
                                        <label className='m-3'>Email :</label>
                                        <input type='email' className='p-3 m-3 rounded w-[90%] outline-none  bg-inputcolor' placeholder='user@gmail.com' name='email' onChange={handleChange} /> <br />
                                        <label className='m-3'>Password :</label>

                                        <input type='password' className='p-3 m-3 rounded w-[90%] outline-none bg-inputcolor' placeholder='*********' name='password' onChange={handleChange} />
                                        <button className=" bg-[#FF7A50] hover:bg-orange-700 text-white font-bold py-2 px-4 m-3 rounded transition duration-300 block w-[90%] text-center">{loading ? 'Please wait...' : 'Login'}
                                        </button>
                                        <p className='m-3 text-xs text-text'>Don't have an Account? <Link to='/signup' className='text-[#ff7a50]' onClick={handleSignup1}>Sign up</Link></p>
                                    </div>
                                </div>
                                <div className='w-[100%] lg:w-[50%] lg:order-2 order-1'>
                                    <img
                                        src={"/assets/userlogin.png"}
                                        alt="whyus"
                                        className="mt-8 w-[70%] flex justify-center mx-auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </AnimatedText>
                <Footer />
            </div>

        </div>
    )
}

export default Login
